import React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import {
  AnnotationIcon,
  CogIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  DocumentSearchIcon,
} from '@heroicons/react/outline';
import { graphql, Link } from 'gatsby';

export default function Landing() {
  const { t } = useTranslation();

  const transferFeatures = [
    {
      id: 1,
      name: t('Save time on document review'),
      description: t(
        'Amplified uses the text and patents you provide to instantly find relevant results.'
      ),
      icon: LightningBoltIcon,
    },
    {
      id: 2,
      name: t('Customize every search'),
      description: t(
        'Fine-tune the AI using relevant results you find. Use keyword filters to focus on key features.'
      ),
      icon: CogIcon,
    },
    {
      id: 3,
      name: t("You're always in control of scope"),
      description: t(
        "AI will sort but never exclude results. Each project keeps track of the patents you've read and saved so you've always got a history."
      ),
      icon: GlobeAltIcon,
    },
  ];

  const communicationFeatures = [
    {
      id: 1,
      name: t("Finds results you'd otherwise miss"),
      description: t(
        "Searching by concept means you'll find similar patents no matter how they are written."
      ),
      icon: DocumentSearchIcon,
    },
    {
      id: 2,
      name: t('Comfortable and easy to use'),
      description: t(
        'Fast loading, customizable keyword highlights, customizable display, side-by-side full text and drawings, and more.'
      ),
      icon: AnnotationIcon,
    },
  ];

  return (
    <Layout
      title={t('How it works')}
      description={t(
        'Naturally intelligent patent search. Intuitively find relevant patents. Build greater confidence. Get more out of your time.'
      )}
    >
      <main className="mx-auto max-w-7xl px-4 sm:mt-16 sm:px-6">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-5 lg:text-left lg:ml-12 lg:mt-32">
            <h1>
              <span className="font-display block text-4xl tracking-tight text-chocolate sm:text-5xl xl:text-5xl">
                <h3 className="text-bright_gold_120 font-display text-base tracking-wide lg:pb-2 uppercase">
                  <Trans>Augmented Intelligence</Trans>
                </h3>
                <span className="block">
                  <Trans>Naturally intelligent</Trans>
                </span>
                <span className="block">
                  <Trans>patent search</Trans>
                </span>
              </span>
            </h1>
            <p className="mt-3 text-base text-chocolate sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              <Trans>AI for simpler and more effective patent search</Trans>
            </p>
          </div>
          <div className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-xl lg:mx-auto lg:col-span-7 lg:flex lg:items-center">
            <StaticImage
              src="../images/how-it-works-banner.png"
              className="relative mx-auto w-full"
              placeholder="tracedSVG"
              alt="How it works"
            />
          </div>
        </div>
      </main>
      <div className="py-16 bg-gray_0.5 overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray_1"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="font-display text-center text-3xl leading-8 font-extrabold tracking-tight text-chocolate sm:text-4xl">
              <Trans>Amplified is different</Trans>
            </h2>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
              <Trans>
                Amplified is more accurate and comprehensive because we match
                similar concepts based on reading the entire text, not specific
                keywords.
              </Trans>
            </p>
          </div>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-chocolate tracking-tight sm:text-3xl">
                <Trans>AI sorts while you search</Trans>
              </h3>
              <p className="mt-3 text-lg text-gray_4.5">
                <Trans>
                  Document-level similarity adds a new dimension to your search
                  that works hand-in-hand with traditional queries.
                </Trans>
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-pastel_red text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-chocolate">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray_4.5">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray_1"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <StaticImage
                src="../images/feature-example.png"
                className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
                imgClassName="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                placeholder="blurred"
                alt="Screenshot"
              />
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray_1"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>

          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-chocolate tracking-tight sm:text-3xl">
                  <Trans>Craft patents, not queries</Trans>
                </h3>
                <p className="mt-3 text-lg text-gray_4.5">
                  <Trans>
                    Amplified learns what you're looking for based on the text
                    and patents you provide.
                  </Trans>
                </p>

                <dl className="mt-10 space-y-10">
                  {communicationFeatures.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-pastel_red text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-chocolate">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray_4.5">
                        {item.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width={784}
                  height={404}
                  fill="none"
                  viewBox="0 0 784 404"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray_1"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={784}
                    height={404}
                    fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                  />
                </svg>
                <StaticImage
                  imgClassName="relative mx-auto"
                  width={490}
                  src="../images/rigorously-tested.png"
                  alt="Rigorously tested"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
          <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-bright_gold_120 lg:pr-16">
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg font-medium md:flex-grow">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-9 -translate-y-2 h-8 w-8 text-pastel_red"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative text-chocolate font-bold">
                  I was able to quickly find the most relevant prior art in
                  Amplified. I verified this after spending significantly more
                  time in our standard patent search database.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div>
                    <div className="text-xl text-chocolate">
                      Sylwia Jacobsen
                    </div>
                    <div className="text-base text-chocolate">
                      Patent Analyst, Laudea GmbH
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
          <div className="py-12 px-4 border-t-2 border-bright_gold_120 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg font-medium md:flex-grow">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-9 -translate-y-2 h-8 w-8 text-pastel_red"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative font-bold text-chocolate">
                  Amplified is easy to use for attorneys and engineers. In under
                  an hour we can find prior art.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div>
                    <div className="text-xl text-chocolate">
                      Fumihiko Matsumoto
                    </div>
                    <div className="text-base text-chocolate">
                      Patent Attorney, Matsumoto Patent Office
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <div className="relative py-16 bg-white">
        <div className="max-w-7xl mx-auto lg:bg-transparent lg:px-8">
          <div className="lg:grid lg:grid-cols-12">
            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
              <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                  <StaticImage
                    src="../images/team.png"
                    imgClassName="object-cover object-center rounded-3xl"
                    placeholder="blurred"
                    alt="The team"
                  />
                </div>
              </div>
            </div>

            <div className="relative bg-gray_0.5 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
              <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                <h2
                  className="text-3xl font-display font-extrabold text-chocolate"
                  id="join-heading"
                >
                  <Trans>Ready to try it yourself?</Trans>
                </h2>
                <p className="text-lg text-chocolate">
                  <Trans>
                    Our goal is to deliver faster initial prior art and greater
                    confidence in your final results. Get in touch for a free
                    trial.
                  </Trans>
                </p>
                <Link
                  className="w-full px-6 py-3 text-base font-medium font-display rounded-md text-white bg-pastel_red hover:bg-pastel_red_120 focus:outline-none focus:ring-2 focus:ring-bright_gold sm:mt-0 flex-shrink-0 inline-flex sm:items-center sm:w-auto"
                  to="/request-trial-account"
                >
                  <Trans>Request a trial</Trans>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
